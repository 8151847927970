<template>
  <div>
    <div class="head">
      <div class="childImg" >
        <img @click="childImgClick" v-if="obj.gender == 0" src="../../common/img/girl.png" />
        <img @click="childImgClick" v-if="obj.gender == 1" src="../../common/img/boy.png" />
      </div>
      <div class="childInfo">
        <div class="name">
          {{ obj.name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ obj.age }}岁
        </div>
        <div class="kindergarten">{{ obj.createName }}</div>
      </div>
    </div>
    <div class="content">
      <van-cell
        title="我的体测结果"
        value="详情"
        is-link
        :to="{ path: (obj.origin===1)?'reportApp':'report', query: (obj.origin===1)? { stuId:obj.niubi,schoolId:obj.userId } : { id: obj.stuId }}"
      >
        <template #icon>
          <img class="img" src="../../common/img/report.png" />
        </template>
      </van-cell>

      <div class="middle">
        <div class="reportBtn" @click="goDetail(1)">
          <img
            src="../../common/img/height.png"
            alt=""
            class="report-next-btn"
          />
          <p>身高素质</p>
        </div>
        <div class="reportBtn" @click="goDetail(2)">
          <img
            src="../../common/img/constitution.png"
            alt=""
            class="report-next-btn"
          />
          <p>体质免疫力</p>
        </div>
        <div class="reportBtn" @click="goDetail(3)">
          <img
            src="../../common/img/contact.png"
            alt=""
            class="report-next-btn"
          />
          <p>社交能力</p>
        </div>
      </div>

      <!-- <van-cell title="我的提升方案" value="详情" is-link to="plan">
        <template #icon>
          <img class="img" src="../../common/img/upgrade.png" />
        </template>
      </van-cell> -->
    </div>
  </div>
</template>

<script>
import api from '../../common/js/api';
import { getToken } from '../../common/js/cache';
export default {
  data() {
    return {
      obj: {},
      id: '',
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {
    if (getToken()) this.getStuList();
  },

  methods: {
    goDetail(id) {
      this.$router.push({
        path: '/detail',
        query: { type: id },
      });
    },
    getStuList() {
      this.$post(api.getStuList, { origin: 1 }).then((res) => {
        if (res.success) {
          if (res.data.length > 0) {
            window.sessionStorage.setItem(
              'childList',
              JSON.stringify(res.data)
            );
            if (window.sessionStorage.getItem('childId')) {
              this.id = window.sessionStorage.getItem('childId');
            } else {
              window.sessionStorage.setItem('childId', res.data[0].id);
              this.id = res.data[0].id;
            }

            this.getInfo();
          } else {
            this.$router.replace('/bind');
          }
        } else {
          this.$toast(res.message);
        }
      });
    },
    getInfo() {
      console.log(111);
      this.$post(api.childInfoService, {
        id: this.id,
      }).then((res) => {
        this.obj = res.data;
        this.obj.niubi = (this.obj.stuId+"").substring(1)
        window.sessionStorage.setItem('userInfo', JSON.stringify(this.obj));
      });
    },
    childImgClick() {
      this.$router.push('/childInfo');
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  height: 296px;
  background: url('../../common/img/bg.png') 50%;
  background-size: contain;
  text-align: center;
  color: white;
  .childImg {
    padding-top: 120px;
    img {
      width: 74px;
      height: 74px;
    }
  }
  .childInfo {
    .name {
      margin: 17px 0 10px 0;
      font-size: 17px;
      font-weight: bold;
    }
    .kindergarten {
      font-size: 15px;
      color: #abdaff;
    }
  }
}
.content {
  margin: 20px 10px 20px 10px;
  /deep/.van-cell {
    align-items: center;
    line-height: 40px;
    box-shadow: 0 1px 5px 0 #e4e5e9;
    border-radius: 5px;
    color: black;
    .van-cell__value {
      color: black;
    }
  }
  .img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .middle {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin: 15px 0 15px 0;
    .reportBtn {
      background: #fff;
      float: left;
      font-size: 12px;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0 0 5px 3px #e4e5e9;
      .report-next-btn {
        display: block;
        width: 105px;
        height: 94px;
      }
      p {
        font-size: 15px;
        margin: 6px;
        font-weight: bold;
      }
    }
  }
}
</style>
